<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="500"
  >
    <v-card>
      <v-card-title class="headline">
        Вы уверены?
      </v-card-title>
      <v-card-text>{{label}}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="dialog = false"
        >
          Нет
        </v-btn>
        <v-btn
          color="success"
          @click="submitDelete"
        >
          Да
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "DeleteDialog",
  props: ["label"],
  data() {
    return {
      dialog: false,
      id: null,
    };
  },
  methods: {
    async submitDelete() {
      this.$emit('delete-confirmed', this.id)
      this.close();
    },
    close() {
      this.dialog = false;
    },
    open(id) {
      this.dialog = true;
      this.id = id;
    }
  }
}
</script>
