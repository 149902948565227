<template>
  <v-card id="promotion-list">
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">
        <v-btn
          color="primary"
          class="me-3"
          :disabled="hasNotPermission('Permissions.PromotionCreate')"
          :to="{ name: 'promotion-add' }"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>Добавить</span>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <div class="d-flex align-center pb-5">
        <v-text-field
          v-model="filters.searchQuery"
          single-line
          dense
          outlined
          hide-details
          placeholder="Поиск..."
          class="promotion-list-search me-3"
        ></v-text-field>
      </div>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="promotions"
      :server-items-length="totalCount"
      :loading="loading"
      :page.sync="filters.pageNumber"
      :items-per-page.sync="filters.pageSize"
      :sort-by.sync="filters.sortBy"
      :sort-desc.sync="filters.sortDesc"
      class="text-no-wrap"
    >
      <template #[`item.name`]="{item}">
        {{ item.name }}
      </template>
      <template #[`item.publicationDate`]="{item}">
        <span class="text-no-wrap">{{ toDate(item.publicationDate) }}</span>
      </template>
      <template #[`item.endDate`]="{item}">
        <span class="text-no-wrap">{{ toDate(item.endDate) }}</span>
      </template>
      <template #[`item.isBanner`]="{item}">
        <v-icon v-if="item.isBanner"
                size="20"
                color="success"
        >
          {{ icons.mdiCheck }}
        </v-icon>
      </template>
      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                :disabled="hasNotPermission('Permissions.PromotionUpdate')"
                v-bind="attrs"
                :to="{ name: 'promotion-edit', params: { id: item.id } }"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Редактирование</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                :disabled="hasNotPermission('Permissions.PromotionDelete')"
                v-bind="attrs"
                v-on="on"
                @click="deletePopupShow(item.id, item.name)"
              >
                <v-icon size="18">
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Удалить</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <delete-dialog @delete-confirmed="deletePromotion" :label="deleteLabel" ref="deletePopup" />
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFormHandlers } from '@/helpers/forms'
import DeleteDialog from '@/components/delete-dialog'
import {
  mdiTrendingUp,
  mdiPlus,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiCheck,
} from '@mdi/js'
import { hasPermission, toDateSting } from '@/helpers/index'

export default {
  components: { DeleteDialog },
  name: 'PromotionList',
  data: () => ({
    icons: {
      mdiTrendingUp,
      mdiPlus,
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiDotsVertical,
      mdiCheck,
      mdiEyeOutline,
    },
    deleteLabel: '',
    deleteMethod: null,
  }),
  computed: {
    ...mapFormHandlers('promotion', [
      'filters.pageNumber',
      'filters.pageSize',
      'filters.searchQuery',
      'filters.sortBy',
      'filters.sortDesc',
    ]),
    ...mapState('promotion', { promotions: 'list', totalCount: 'totalCount', loading: 'loading' }),
    headers() {
      return [
        { text: 'Наименование', value: 'name' },
        { text: 'Дата публикации', value: 'publicationDate' },
        { text: 'Дата окончания', value: 'endDate' },
        { text: 'Баннер', value: 'isBanner' },
        {
          text: 'Действия',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ]
    },
  },
  async created() {
    document.title = 'Акции'
    this.fetchPromotions()
  },
  mounted() {
  },
  methods: {
    toDate(val) {
      return toDateSting(val)
    },
    hasNotPermission(val) {
      return !hasPermission(val)
    },
    ...mapActions('promotion', { fetchPromotions: 'fetchList', deletePromotion: 'delete' }),
    deletePopupShow(id, name) {
      this.deleteLabel = 'Акция ' + name + ' будет удалена.'
      this.$refs.deletePopup.open(id)
    },
    getBannerString(isBanner) {
      return isBanner ? 'Да' : 'Нет'
    },
    bannerColor(isBanner) {
      return isBanner ? 'success' : 'error'
    },
  },
}
</script>

<style lang="scss" scoped>
#promotion-list {
  .promotion-list-search {
    max-width: 10.625rem;
  }
}
</style>
